

























































































































.templates-container {
  -moz-column-count: 1;
  -moz-column-gap: 25px;
  -webkit-column-count: 1;
  -webkit-column-gap: 25px;
  column-count: 1;
  column-gap:25px;
}
.template-card {

}
